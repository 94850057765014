import { Button, FormControl, FormLabel, Grid, Heading, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Skeleton, useDisclosure } from "@chakra-ui/react";
import React from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import AddConsumer from "../Components/AddConsumer";
import ConsumerCard from "../Components/ConsumerCard";
import Consumer from "../Interfaces/Models/Consumer";
import Tenant from "../Interfaces/Models/Tenant";
import { GetConsumerExport } from "../Repositories/ExportsRepository";
import { GetTenant } from "../Repositories/TenantsRepository";

let fetchedData: boolean = false;

export default function TenantDetailPage(props: any) {
    const msalInstance = props.msalInstance;
    const { id: tenantId, ownerId: ownerId } = useParams()
    const [loading, setLoading] = useState(true);
    const [consumers, setConsumers] = useState(new Array<Consumer>())
    const [tenant, setTenant] = useState<Tenant>()
    const [navTitle, setNavTitle] = useState<string>('')
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [currentConsumer, setCurrentConsumer] = useState<Consumer>()
    const initialRef = React.useRef(null)
    const onFromDateChange = (e: any) => { setFromDate(e.target.value) };
    const onToDateChange = (e: any) => { setToDate(e.target.value) };
    const [fromDate, setFromDate] = useState<string>("")
    const [toDate, setToDate] = useState<string>("")

    const onOpenExportModal = (e: any) => {
        var currentConsumer = e as Consumer
        onOpen()
        setCurrentConsumer(currentConsumer)
    };
    const onConsumerUpserted = (e: any) => {
        setLoading(true)
        GetTenant(props.msalInstance, tenantId, ownerId, callbackSetTenant);
    };

    const callbackSetTenant = (data: Tenant) => {
        setTenant(data);
        setNavTitle(data.name)
        setConsumers(data.consumers)
        setLoading(false)
    }

    if (!fetchedData) {
        fetchedData = true;
        GetTenant(props.msalInstance, tenantId, ownerId, callbackSetTenant);
    }

    const onClickExecute = () => {
        GetConsumerExport(msalInstance,
            currentConsumer!.rfid,
            {
                "from": fromDate,
                "to": toDate
            },
            (data: Blob) => {
                const fileURL = window.URL.createObjectURL(data);
                let alink = document.createElement('a');
                alink.href = fileURL;
                alink.download = currentConsumer!.rfid + "_" + fromDate + "_" + toDate + '.csv';
                alink.click();

                window.URL.revokeObjectURL(fileURL)
                onClose()
            });
    };

    return (
        <>
            <Skeleton isLoaded={!loading}>
                <Heading fontSize={'3xl'}
                    mt={2}
                    ml={1}
                    mb={5}>
                    {navTitle}
                </Heading>

                <Grid
                    gap={5}
                    templateColumns={'repeat(auto-fit, 300px)'}>
                    {
                        consumers.map((consumer =>
                            <ConsumerCard
                                key={consumer.id}
                                tenantId={tenantId}
                                model={consumer}
                                instance={msalInstance}
                                ownerId={ownerId}
                                callbackSetExportData={onOpenExportModal}
                                callback={onConsumerUpserted} />
                        ))
                    }
                    <AddConsumer tenantId={tenantId} instance={props.msalInstance} ownerId={ownerId} callback={onConsumerUpserted} />
                </Grid>
            </Skeleton>

            <Modal
                isCentered
                initialFocusRef={initialRef}
                isOpen={isOpen}
                onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>CSV Export erstellen für {currentConsumer?.firstname} {currentConsumer?.lastname}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6}>
                        <FormControl>
                            <FormLabel>
                                von
                            </FormLabel>
                            <Input
                                type={'date'}
                                ref={initialRef}
                                placeholder='2002-08-01'
                                onChange={onFromDateChange} />
                        </FormControl>
                        <FormControl mt={4}>
                            <FormLabel>
                                bis
                            </FormLabel>
                            <Input
                                type={'date'}
                                placeholder='2002-09-01'
                                onChange={onToDateChange} />
                        </FormControl>
                    </ModalBody>

                    <ModalFooter>
                        <Button
                            onClick={onClickExecute}
                            color={'white'}
                            bg={'orange'}
                            size='lg'
                            _hover={{
                                bg: 'brand.400',
                                color: 'white',
                            }}
                            mr={3}>
                            Erstellen
                        </Button>
                        <Button onClick={onClose}>Abbrechen</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}