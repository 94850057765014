import { useState } from "react";
import { AdminInfo } from "../Interfaces/Models/AdminInfo";
import { MaintainerSummary } from "../Interfaces/Models/MaintainerSummary";
import { Button, Flex, FormControl, FormErrorMessage, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";

export default function UpsertMaintainerModal(props: any) {
    const model = props.model as MaintainerSummary;
    var pressed = false;

    const [name, setName] = useState(() => {
        var res = ''
        if (model != null) {
            res = model.name
        }

        return res;
    })

    const [admins, setAdmins] = useState(() => {
        var res = Array<AdminInfo>()
        if (model != null) {
            res = model.admins
        }

        return res;
    })

    const onNameChange = (e: any) => setName(e.target.value)
    const onAdminsChange = (e: any) => setAdmins(e.target.value)

    const isTenantNameError = ValidateName(name)

    const onConfirmHandler = () => {
        if (!pressed) {
            pressed = true;
            var data = {
                "id": model === undefined ? '' : model.id,
                "name": name,
                "admins": admins
            } as MaintainerSummary
            props.onConfirmCallback(data)
        }
    }

    return (
        <Modal
            isCentered
            initialFocusRef={props.initialRef}
            isOpen={props.isOpen}
            onClose={props.onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{props.title}</ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={6}>
                    <FormControl isInvalid={isTenantNameError}>
                        <Input
                            type={'text'}
                            ref={props.initialRef}
                            placeholder='Name'
                            onChange={onNameChange}
                            value={name} />
                        {
                            !isTenantNameError
                                ? <></>
                                : (<FormErrorMessage>Bitte geben Sie dem Kunden einen Namen</FormErrorMessage>)
                        }
                    </FormControl>
                    {/* <h1>Admins</h1>
                    <Table>
                        <Thead>
                            <Th>Email</Th>
                            <Th isNumeric>Aktion</Th>
                        </Thead>
                        <Tbody>
                            <Tr>
                                <Td>
                                    phonyot.w@gmail.com
                                </Td>
                            </Tr>
                            <Tr>
                                <Td>
                                    <Input
                                        type={'text'}
                                        ref={props.initialRef}
                                        placeholder='Name'
                                        onChange={onNameChange}
                                        value={name} />
                                </Td>
                                <Td isNumeric>
                                    <Button>
                                        Hinzufügen
                                    </Button>
                                </Td>
                            </Tr>
                        </Tbody>
                    </Table> */}

                    {/* <FormControl mt={4}>
                        <Flex>
                            <Input onChange={onStreetChange} flex={4} type={'text'} placeholder='Straße' value={tenantStreet} />
                            <Input onChange={onHousenumberChange} ml={1} flex={1} type={'text'} placeholder='Nummer' value={tenantHousenumber} />
                        </Flex>
                    </FormControl>

                    <FormControl mt={4}>
                        <Flex>
                            <Input onChange={onPostalCodeChange} flex={1} type={'number'} placeholder='PLZ' value={tenantPostalCode} />
                            <Input onChange={onCityChange} ml={1} flex={4} type={'text'} placeholder='Ort' value={tenantCityName} />
                        </Flex>
                    </FormControl> */}
                </ModalBody>

                <ModalFooter>
                    <Button
                        onClick={onConfirmHandler}
                        disabled={pressed}
                        color={'white'}
                        bg={'orange'}
                        size='lg'
                        _hover={{
                            bg: 'brand.400',
                            color: 'white',
                        }}
                        mr={3}>
                        {props.confirmText}
                    </Button>
                    <Button onClick={props.onClose}>Abbrechen</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}

function ValidateName(name: string) {
    return name === '';
}