import { Tr, Td, Input, Button } from "@chakra-ui/react";
import { useState } from "react";
import { AdminInfo } from "../Interfaces/Models/AdminInfo";
import { MaintainerSummary } from "../Interfaces/Models/MaintainerSummary";

export function AddAdminRow(props: any) {
    const model = props.model as AdminInfo
    const maintainer = props.maintainer as MaintainerSummary

    var pressed = false;

    const [email, setEmail] = useState(() => {
        var res = ''
        if (model != null) {
            res = model.email
        }

        return res;
    })


    const onEmailChange = (e: any) => setEmail(e.target.value)

    const onAddHandler = () => {
        if (!pressed) {
            pressed = true;
            var data = {
                "id": '',
                "email": email
            } as AdminInfo 
            props.onConfirmCallback(data)
        }
    }

    return (<>
        <Tr>
            <Td>
                <Input
                    type={'text'}
                    ref={props.initialRef}
                    placeholder='Email'
                    onChange={onEmailChange}
                    value={email} />
            </Td>
            <Td isNumeric>
                <Button
                    onClick={onAddHandler}
                    disabled={pressed}
                    color={'white'}
                    bg={'orange'}
                    size='lg'
                    _hover={{
                        bg: 'brand.400',
                        color: 'white',
                    }}
                    mr={3}>
                    Hinzufügen
                </Button>
            </Td>
        </Tr>
    </>)
}