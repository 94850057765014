import { useState } from "react";
import { AdminInfo } from "../Interfaces/Models/AdminInfo";
import { MaintainerSummary } from "../Interfaces/Models/MaintainerSummary";
import { Button, Flex, FormControl, FormErrorMessage, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { AdminRow } from "./AdminRow";
import { AddAdminRow } from "./AddAdminRow";

export default function UpdateMaintainerModal(props: any) {
    const model = props.model as MaintainerSummary;
    var pressed = false;

    const [name, setName] = useState(() => {
        var res = ''
        if (model != null) {
            res = model.name
        }

        return res;
    })

    const [admins, setAdmins] = useState(() => {
        var res = Array<AdminInfo>()
        if (model != null) {
            res = model.admins
        }

        return res;
    })

    const onNameChange = (e: any) => setName(e.target.value)
    const onAdminsChange = (e: any) => setAdmins(e.target.value)

    const isTenantNameError = ValidateName(name)

    const onConfirmHandler = () => {
        if (!pressed) {
            pressed = true;
            var data = {
                "id": model === undefined ? '' : model.id,
                "name": name,
                "admins": admins
            } as MaintainerSummary
            props.onConfirmCallback(data)
        }
    }

    const onAbortHandler = () => {
        // todo clear unsaved admins
        props.onClose()
    }

    const onAddAdminCallback = (adminInfoData: any) => {
        setAdmins(admins => [...admins, adminInfoData])
    }

    const onRemoveAdminCallback = (adminInfoData: any) => {
        var index = admins.findIndex(item => item.id === adminInfoData.id);

        admins.splice(index, 1);
        setAdmins(admins => [...admins])
    }

    return (
        <Modal
            isCentered
            initialFocusRef={props.initialRef}
            isOpen={props.isOpen}
            onClose={props.onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{props.title}</ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={6}>
                    <FormControl isInvalid={isTenantNameError}>
                        <Input
                            type={'text'}
                            ref={props.initialRef}
                            placeholder='Name'
                            onChange={onNameChange}
                            value={name} />
                        {
                            !isTenantNameError
                                ? <></>
                                : (<FormErrorMessage>Bitte geben Sie dem Kunden einen Namen</FormErrorMessage>)
                        }
                    </FormControl>
                    <h1>Admins</h1>
                    <Table>
                        <Thead>
                            <Tr>
                                <Th>Email</Th>
                                <Th isNumeric>Aktion</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {
                                admins.map((admin =>
                                    <AdminRow
                                        key={admin.email}
                                        model={admin}
                                        onDeleteCallback={onRemoveAdminCallback} />
                                ))
                            }
                            <AddAdminRow maintainer={model} onConfirmCallback={onAddAdminCallback} />
                        </Tbody>
                    </Table>
                </ModalBody>

                <ModalFooter>
                    <Button
                        onClick={onConfirmHandler}
                        disabled={pressed}
                        color={'white'}
                        bg={'orange'}
                        size='lg'
                        _hover={{
                            bg: 'brand.400',
                            color: 'white',
                        }}
                        mr={3}>
                        {props.confirmText}
                    </Button>
                    <Button onClick={onAbortHandler}>Abbrechen</Button>
                </ModalFooter>
            </ModalContent>
        </Modal >
    );
}

function ValidateName(name: string) {
    return name === '';
}