import { useNavigate } from "react-router-dom";
import { MaintainerSummary } from "../Interfaces/Models/MaintainerSummary";
import React from "react";
import {
    Box,
    Text,
    useColorModeValue,
    Icon,
    Grid,
    GridItem,
    Flex,
    Button,
    IconButton,
    useDisclosure,
} from '@chakra-ui/react';
import { GoLocation } from "react-icons/go";
import { BsArrowReturnRight } from "react-icons/bs";
import { AiFillEdit, AiFillDelete } from "react-icons/ai";
import UpdateMaintainerModal from "./UpdateMaintainerModal";
import { DeleteMaintainer, UpdateMaintainer } from "../Repositories/MaintainerRepository";
import ConfirmDeleteMaintainerModal from "./ConfirmDeleteMaintainerModal";

export default function MaintainerCard(props: any) {
    let navigate = useNavigate();
    const model = props.model as MaintainerSummary;
    const initialRef = React.useRef(null)
    const { isOpen: isUpsertOpen, onOpen: onUpsertOpen, onClose: onUpsertClose } = useDisclosure()
    const { isOpen: isDeleteOpen, onOpen: onDeleteOpen, onClose: onDeleteClose } = useDisclosure()

    const callbackOnUpdated = () => {
        props.callback();
        onUpsertClose();
    }

    const callbackOnDeleted = () => {
        props.callback();
        onDeleteClose();
    }

    const onUpdateCallback = (data: MaintainerSummary) => {
        UpdateMaintainer(data, props.instance, callbackOnUpdated)
    }

    const onDeleteCallback = () => {
        DeleteMaintainer(model.id, props.instance, callbackOnDeleted)
    }

    const onDetailsClicked = () => {
        navigate("/customers/" + model.id);
    }

    return (
        <>
            <Box >
                <Box
                    maxW={'300px'}
                    minWidth={'300px'}
                    maxH={'250px'}
                    minHeight={'250px'}

                    bg={useColorModeValue('white', 'gray.100')}
                    boxShadow={'2xl'}
                    rounded={'md'}
                    p={6}>
                    <Box
                        h={'100px'}
                        bg={'orange'}
                        mt={-6}
                        mx={-6}
                        mb={6}
                        pos={'relative'}>
                        <Grid
                            templateAreas={`"header header header"
                                        "nav main symbol"
                                        "nav footer symbol"`}
                            gridTemplateRows={'50px 20px 30px'}
                            gridTemplateColumns={'60px 160px 80px'}

                            color='blackAlpha.700'
                            fontWeight='bold'>

                            <GridItem area={'header'}>
                                <Text
                                    color={'white'}
                                    py={6}
                                    px={6}
                                    fontSize={'lg'}
                                    noOfLines={1}
                                    letterSpacing={1.1}
                                    maxWidth={300}
                                    maxHeight={50}>
                                    {model.name}
                                </Text>
                            </GridItem>
                            <GridItem area={'nav'}>
                                <Icon
                                    color={'white'}
                                    as={GoLocation}
                                    fontSize={25}
                                    display={'block'}
                                    mt={3}
                                    ml={4}
                                    alignContent={'center'} />
                            </GridItem>

                        </Grid>
                    </Box>

                    <Grid>
                        <GridItem>
                            <Grid
                                templateAreas={`"consumers  export"
                                            "totalkwh      details"
                                            "blank      ud"`}
                                gridTemplateRows={'50px 50px'}
                                gridTemplateColumns={'160px 105px'}>
                                <GridItem area={'details'}>
                                    <Button size={'md'} onClick={onDetailsClicked}>
                                        <Flex justifyContent={'right'} align={'center'} >
                                            <Text mr={2}>Details</Text>
                                            <Icon as={BsArrowReturnRight} fontSize={'1rem'} />
                                        </Flex>
                                    </Button>
                                </GridItem>
                                <GridItem area={'ud'}>
                                    <Flex justifyContent={'right'} align={'center'}>
                                        <IconButton
                                            variant='outline'
                                            aria-label='Edit Tenant'
                                            size={'xs'}
                                            icon={<AiFillEdit />}
                                            onClick={onUpsertOpen} />
                                        <IconButton
                                            variant='outline'
                                            aria-label='Delete Tenant'
                                            size={'xs'}
                                            icon={<AiFillDelete />}
                                            onClick={onDeleteOpen} />
                                    </Flex>
                                </GridItem>
                            </Grid>
                        </GridItem>
                    </Grid>
                </Box >
            </Box >

            <UpdateMaintainerModal
                onConfirmCallback={onUpdateCallback}
                initialRef={initialRef}
                isOpen={isUpsertOpen}
                onClose={onUpsertClose}
                title="Kunden bearbeiten"
                model={model}
                confirmText="Bearbeiten"
            />

            <ConfirmDeleteMaintainerModal
                onConfirmCallback={onDeleteCallback}
                initialRef={initialRef}
                isOpen={isDeleteOpen}
                onClose={onDeleteClose}
                model={model}
            />
        </>
    );
}