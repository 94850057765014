import { Tr, Td, Text, IconButton } from "@chakra-ui/react";
import { AiFillDelete } from "react-icons/ai";
import { CustomerAdminInfo } from "../Interfaces/Models/CustomerAdminInfo";

export function CustomerAdminRow(props: any) {
    const model = props.model as CustomerAdminInfo
    var pressed = false;

    const onDeleteHandler = () => {
        if (!pressed) {
            pressed = true;
            var data = {
                "id": model.id,
                "email": model.email
            } as CustomerAdminInfo
            props.onDeleteCallback(data)
        }
    }

    return (<>
        <Tr>
            <Td><Text>{model.email}</Text></Td>
            <Td isNumeric>
                <IconButton
                    variant='outline'
                    aria-label='Delete CustomerAdminInfo'
                    size={'xs'}
                    icon={<AiFillDelete />}
                    onClick={onDeleteHandler} />
            </Td>
        </Tr>
    </>);
}