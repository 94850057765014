import { Box, Button, useDisclosure } from "@chakra-ui/react";
import React from "react";
import Tenant from "../Interfaces/Models/Tenant";
import { CreateTenant } from "../Repositories/TenantsRepository";
import UpsertTenantModal from "./UpsertTenantModal";

export default function AddTenant(props: any) {
    const initialRef = React.useRef(null)
    const { isOpen, onOpen, onClose } = useDisclosure()

    const callbackOnTenantCreated = () => {
        props.callback();
        onClose();
    }

    const onConfirmCallback = (data: Tenant) => {
        CreateTenant(data, props.instance, props.ownerId, callbackOnTenantCreated);
    }

    return (
        <>
            <Box>
                <Button
                    onClick={onOpen}

                    maxW={'300px'}
                    minWidth={'300px'}
                    maxH={'250px'}
                    minHeight={'250px'}

                    boxShadow={'2xl'}
                    rounded={'md'}
                    p={6}

                    color={'white'}
                    bg={'orange'}
                    size='lg'
                    _hover={{
                        bg: 'brand.400',
                        color: 'white',
                    }}>
                    Lokation hinzufügen
                </Button>
            </Box>

            <UpsertTenantModal
                onConfirmCallback={onConfirmCallback}
                initialRef={initialRef}
                isOpen={isOpen}
                onClose={onClose}
                title={"Lokation hinzufügen"}
                confirmText="Erstellen"
            />
        </>
    )
}


