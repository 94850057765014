import { Tr, Td, Text, IconButton } from "@chakra-ui/react";
import { AdminInfo } from "../Interfaces/Models/AdminInfo";
import { MaintainerSummary } from "../Interfaces/Models/MaintainerSummary";
import { useState } from "react";
import { AiFillDelete } from "react-icons/ai";

export function AdminRow(props: any) {
    const model = props.model as AdminInfo
    var pressed = false;

    const onDeleteHandler = () => {
        if (!pressed) {
            pressed = true;
            var data = {
                "id": model.id,
                "email": model.email
            } as AdminInfo
            props.onDeleteCallback(data)
        }
    }

    return (<>
        <Tr>
            <Td><Text>{model.email}</Text></Td>
            <Td isNumeric>
                <IconButton
                    variant='outline'
                    aria-label='Delete Tenant'
                    size={'xs'}
                    icon={<AiFillDelete />}
                    onClick={onDeleteHandler} />
            </Td>
        </Tr>
    </>);
}